<template>
  <div>
    <p class="subtile has-text-left has-text-weight-bold">
      {{ workspace.SpaceName }}
    </p>
    <ul class="has-text-left">
      <li v-for="setting in workspace.Settings" :key="setting.Id">
        <span>{{
          setting.PricePerSeat | toCurrency("nl-NL", setting.CurrencyIso)
        }}</span>
        <span>
          <a
            @click="selectSetting(setting)"
            class="button is-success is-small"
            >{{
              $t("Components.Availability.AvailableWorkspace.Link_Select")
            }}</a
          >
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { EventBus } from "@/eventbus/event-bus";

export default {
  props: {
    workspace: null
  },

  data() {
    return {};
  },

  methods: {
    selectSetting(setting) {
      let self = this;
      let space = self.workspace;

      let workspace = {
        Id: 0,
        LocationId: self.workspace.LocationId,
        SpaceId: self.workspace.Id,
        SpaceName: self.workspace.SpaceName,
        SpaceImage: self.workspace.SpaceImage,
        SettingId: setting.Id,
        Seats: self.workspace.Seats,
        StartDate: self.workspace.StartDate,
        StartMinutes: self.workspace.StartMinutes,
        EndDate: self.workspace.EndDate,
        EndMinutes: self.workspace.EndMinutes,
        CurrencyId: setting.CurrencyId,
        PricePerSeat: setting.PricePerSeat,
        PriceTotal: setting.PricePerSeat * self.workspace.Seats,
        TaxId: setting.TaxId,
        TaxPercentage: setting.TaxPercentage,
        Hash: setting.Hash
      };

      EventBus.$emit("spaceSelected", workspace);
    }
  }
};
</script>
