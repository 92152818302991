<template>
  <div>
    <p class="subtile has-text-left">{{ deskspace.SpaceName }}</p>
    <ul class="has-text-left">
      <li v-for="setting in deskspace.Settings" :key="setting.Id">
        <span v-html="setting.CurrencySymbol"></span>
        <span>{{ setting.PricePerSeat.toFixed(2) }}</span>
        <span>
          <a @click="selectSetting(setting)" class="button is-success is-small"
            >Select</a
          >
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'

export default {
  props: {
    deskspace: null,
  },

  data() {
    return {}
  },

  methods: {
    selectSetting(setting) {
      let self = this
      let space = self.deskspace

      self.selectedSettingId = setting.Id

      let deskspace = {
        Id: 0,
        LocationId: self.deskspace.LocationId,
        SpaceId: self.deskspace.SpaceId,
        SpaceName: self.deskspace.SpaceName,
        SpaceImage: self.deskspace.SpaceImage,
        SettingId: setting.Id,
        Seats: self.deskspace.Seats,
        StartDate: self.deskspace.StartDate,
        StartMinutes: self.deskspace.StartMinutes,
        EndDate: self.deskspace.EndDate,
        EndMinutes: self.deskspace.EndMinutes,
        CurrencyId: setting.CurrencyId,
        PricePerSeat: setting.PricePerSeat,
        Crc: setting.Crc,
        PriceTotal: self.roundToTwo(
          setting.PricePerSeat * self.deskspace.Seats
        ),
        TaxId: setting.TaxId,
        TaxPercentage: setting.TaxPercentage,
        Hash: setting.Hash,
      }

      self.$emit('spaceSelected')
      EventBus.$emit('spaceSelected', deskspace)
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },
  },
}
</script>
