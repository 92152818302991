<template>
  <div class="box">
    <h4 class="title is-4" ref="title">{{ meetingspace.SpaceName }}</h4>

    <article
      class="media"
      v-for="setting in meetingspace.Settings"
      :key="setting.Id"
    >
      <figure class="media-left">
        <ui-svg-setting :settingId="setting.Id" :size="'is-large'" />
      </figure>
      <div class="media-content">
        <div class="content">
          <span>{{ setting.Id | getSettingName }}</span>
          <span class="has-margin-left"
            >({{ setting.MinSeats }} - {{ setting.MaxSeats }})</span
          >
        </div>
      </div>
      <div class="media-right">
        <div>
          {{
            setting.PricePerSeat
              | toCurrency(languageCulture, setting.CurrencyIso)
          }}
        </div>
        <button
          @click="selectSetting(setting)"
          class="button is-primary is-small"
          :disabled="spaceSelected"
          :class="{ 'is-loading': selectedSettingId === setting.Id }"
        >
          {{ $t('Components.Availability.AvailableMeetingspace.Link_Select') }}
        </button>
      </div>
    </article>
  </div>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'

export default {
  props: {
    languageCulture: {
      type: String,
      default: 'nl-NL'
    },
    meetingspace: null,
    spaceSelected: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      selectedSettingId: 0
    }
  },

  methods: {
    selectSetting(setting) {
      let self = this
      let space = self.meetingspace

      self.selectedSettingId = setting.Id

      let meetingspace = {
        Id: 0,
        LocationId: self.meetingspace.LocationId,
        SpaceId: self.meetingspace.SpaceId,
        SpaceName: self.meetingspace.SpaceName,
        SpaceImage: self.meetingspace.SpaceImage,
        SettingId: setting.Id,
        Seats: self.meetingspace.Seats,
        StartDate: self.meetingspace.StartDate,
        StartMinutes: self.meetingspace.StartMinutes,
        EndDate: self.meetingspace.EndDate,
        EndMinutes: self.meetingspace.EndMinutes,
        CurrencyId: setting.CurrencyId,
        PricePerSeat: setting.PricePerSeat,
        Crc: setting.Crc,
        PriceTotal: self.roundToTwo(
          setting.PricePerSeat * self.meetingspace.Seats
        ),
        TaxId: setting.TaxId,
        TaxPercentage: setting.TaxPercentage,
        Hash: setting.Hash
      }

      self.$emit('spaceSelected')
      EventBus.$emit('spaceSelected', meetingspace)
    },

    showSpaceSummary() {
      EventBus.$emit('showSpaceSummary', this.meetingspace)
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    }
  }
}
</script>
